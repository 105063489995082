import React, { createContext, useState, useContext } from 'react';

// Imports for making HTTP requests
import axios from 'axios';


// Create AuthContext to provide user data and login/logout methods globally
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);


  // Login function that takes username and password as arguments
  const login = async (username, password) => {
    try {
      // Make a POST request to the login API endpoint with the credentials
      const response = await axios.post(
        '/login', 
        new URLSearchParams({
          username, 
          password
        }), 
        {
          headers: { 
            'Content-Type': 'application/x-www-form-urlencoded' 
          }
        }
      );

      // The response should include a JWT token
      const { token, user } = response.data;
      
      // Save the JWT token in localStorage for persistence
      localStorage.setItem('token', token);

      // Update the user state with the logged-in user's information
      setUser(user);
      console.log('Login successful:', user);
    } catch (error) {
      console.error('Login failed:', error);
      throw new Error('Login failed');
    }
  };

  // Logout function to clear user state and token from localStorage
  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  // Provide user data and login/logout functions to the component tree
  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
