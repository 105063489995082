import React from 'react';
import Header from './Header';
import logo from './assets/aidocx.jpg';

const PrivacyPolicy = () => (
    <>
        <Header logo={logo} />
        <div className="max-w-5xl mx-auto px-4 py-8 bg-white">
            <div className="mb-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-4">Privacy Policy</h1>
                <p className="text-gray-600 leading-relaxed">
                    At AiDocx.co, we are committed to protecting your personal information and maintaining transparency about our data practices. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our platform.
                </p>
            </div>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Information We Collect</h2>
                <p className="text-gray-600 mb-4">The information that we collect is based on your interactions with the company and its services. We may collect the following types of information:</p>
                <ul className="list-disc pl-6 space-y-2 text-gray-600">
                    <li><span className='font-bold'>Personal Information</span>: Name, email address, phone number, usernames, password, contact data and company details provided during registration.</li>
                    <li><span className='font-bold'>Document Data</span>: Information contained in documents uploaded to our platform for processing.</li>
                    <li><span className='font-bold'>Usage Data</span>: Technical information such as device type, and usage metrics.</li>
                </ul>
                <p className="text-gray-600 mt-4">As a user of the system, the information that you provide must be true, complete and accurate and the user is responsible for ensuring the accuracy of the provided information.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. How We Use Your Information</h2>
                <p className="text-gray-600 mb-4">The collected data is used to:</p>
                <ul className="list-disc pl-6 space-y-2 text-gray-600">
                    <li>Deliver and enhance our services, such as document processing and AI-based insights.</li>
                    <li>Improve platform performance through analytics and machine learning algorithms.</li>
                    <li>Communicate with users regarding updates, support, and promotional content.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Data Security</h2>
                <p className="text-gray-600 mb-4">We take data protection seriously and implement robust security measures:</p>
                <ul className="list-disc pl-6 space-y-2 text-gray-600">
                    <li>Data processing occurs within secure infrastructure compliant with industry standards.</li>
                    <li>Encryption protocols protect data in transit and at rest.</li>
                    <li>User documents are encrypted and securely stored in trusted cloud environments, adhering to stringent security and retention policies.</li>
                </ul>
                <p className="text-gray-600 mt-4">While we take extensive precautions to secure your information and minimize risks, no system of data transmission or storage can be guaranteed to be completely secure. This means there is always a possibility that unauthorized individuals, such as hackers or cybercriminals, could overcome our defenses and improperly access, modify, or misuse your data. Although we are committed to protecting your personal information and employ stringent security measures, any exchange of data with our services is done at your own discretion and risk. To enhance your safety, we recommend using our services only in a secure and trusted environment.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Data Retention</h2>
                <p className="text-gray-600">Uploaded files and associated data are retained only for the duration required to provide services, after which they are securely deleted unless otherwise requested by the user. The retention period defaults to zero meaning no storage and the user can instruct the company to change that based on the use case and usage of the product. Personal information is retained as long as it is deemed necessary as set out by this privacy policy.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Sharing of Information</h2>
                <p className="text-gray-600 mb-4">AiDocx.co does not sell or rent your data to third parties. We may share information with:</p>
                <ul className="list-disc pl-6 space-y-2 text-gray-600">
                    <li>Service providers that support our operations under strict confidentiality agreements.</li>
                    <li>Regulatory authorities, if required by law or in connection with legal proceedings.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. User Rights</h2>
                <p className="text-gray-600 mb-4">You have the right to:</p>
                <ul className="list-disc pl-6 space-y-2 text-gray-600">
                    <li>Access, modify, or delete your personal data.</li>
                    <li>Withdraw consent for data processing at any time, where applicable.</li>
                    <li>Request clarification about how your data is processed.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. Changes to This Policy</h2>
                <p className="text-gray-600">We may update this Privacy Policy to reflect changes in our practices or for legal reasons. Users will be notified of significant updates through the platform or via email.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">Contact Us</h2>
                <p className="text-gray-600">For any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:manish@aidocx.co" className="text-blue-500">manish@aidocx.co</a>.</p>
            </section>
        </div>
    </>
);

export default PrivacyPolicy;