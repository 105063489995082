import React, { useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Upload from './Upload';
import ViewPDF from './ViewPDF';
import Login from './Login';
import ViewResults from './ViewResults';
import ViewDetails from './ViewDetails';
import './App.css';

import { useAuth } from './AuthProvider';
import Home from './Home';
import AddCompany from './AddCompany';
import AddUser from './AddUser';
import Analytics from './Analytics';
import logo from './assets/aidocx.jpg';
import CameraCapture from './CameraCapture';
import ContactMessages from './ContactMessages';
import PrivacyPolicy from './PrivacyPolicy';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    logout();
    navigate('/');
  }

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const showSidebar = location.pathname !== '/' && location.pathname !== '/home' && location.pathname !== '/login' && location.pathname !== '/privacy';

  return (
    <div className="App max-h-full">
      <div className="flex h-screen w-screen">
        {/* Sidebar Toggle Button */}
        {showSidebar && (
          <button
            onClick={toggleSidebar}
            className="fixed top-4 left-4 z-50 p-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 focus:outline-none"
          >
            {isSidebarCollapsed ? (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            )}
          </button>
        )}

        {/* Sidebar */}
        {showSidebar && (
          <div
            id="hs-application-sidebar"
            className={`fixed inset-y-0 start-0 bg-[#1e2537] text-gray-300 z-40 w-[200px] border-e transition-transform duration-300 ${isSidebarCollapsed ? '-translate-x-full' : 'translate-x-0'
              }`}
          >
            <div className="relative flex flex-col h-full align-middle">
              <div className="h-full overflow-y-auto bg-[#1e2537] text-gray-300">
                <nav className="hs-accordion-group p-3 flex flex-col" data-hs-accordion-always-open>
                  <ul className="space-y-4 my-auto">
                    <div className='flex justify-center'>
                      <li>
                        <img className="relative z-[2] flex-shrink-0 w-20 h-20 rounded-full border-[3px] border-neutral-800 align-" src={logo} alt="AI DOCX logo" />
                      </li>
                    </div>
                    {(user?.role?.includes('ADMIN') || user?.role?.includes('SITEOWNER')) && (
                      <>
                        <li className='pt-12'>
                          <a
                            className="flex items-center space-x-2 py-2 px-4 rounded hover:bg-gray-700"
                            onClick={() => navigate('/upload')}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m0-3-3-3m0 0-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75" />
                            </svg>
                            Upload Files
                          </a>
                        </li>
                        <li>
                          <a
                            className="flex items-center space-x-2 py-2 px-4 rounded hover:bg-gray-700"
                            onClick={() => navigate('/analytics')}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m0-3-3-3m0 0-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75" />
                            </svg>
                            Analytics
                          </a>
                        </li>
                      </>
                    )}
                    <li>
                      <a
                        className="flex items-center space-x-2 py-2 px-4 rounded hover:bg-gray-700"
                        onClick={() => navigate('/deviceupload')}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                        </svg>
                        Device Upload
                      </a>
                    </li>
                    <li>
                      <a
                        className="flex items-center space-x-2 py-2 px-4 rounded hover:bg-gray-700"
                        onClick={() => navigate('/viewpdf')}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                        </svg>
                        Review Files
                      </a>
                    </li>
                    <li className='pb-8'>
                      <a
                        className="flex items-center space-x-2 py-2 px-4 rounded hover:bg-gray-700"
                        onClick={() => navigate('/results/')}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                        </svg>
                        View Results
                      </a>
                    </li>

                    {user?.role?.includes('SITEOWNER') && (
                      <>
                        <hr />
                        <li className='pt-8'>
                          <a
                            className="flex items-center space-x-2 py-2 px-4 rounded hover:bg-gray-700"
                            onClick={() => navigate('/newcompany')}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z" />
                            </svg>

                            New Company
                          </a>
                        </li>
                        <li>
                          <a
                            className="flex items-center space-x-2 py-2 px-4 rounded hover:bg-gray-700"
                            onClick={() => navigate('/newuser')}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="size-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                            </svg>

                            New User
                          </a>
                        </li>

                        <li>
                          <a
                            className="flex items-center space-x-2 py-2 px-4 rounded hover:bg-gray-700"
                            onClick={() => navigate('/contacts')}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="size-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                            </svg>

                            User Messages
                          </a>
                        </li>

                      </>
                    )}
                    <li>
                      <a
                        className="flex items-center space-x-2 py-2 px-4 rounded hover:bg-gray-700"
                        onClick={handleLogout} href='/'
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        Logout
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        )}

        {/* Main Content Area */}
        <div
          className={`flex-1 transition-all duration-300 ${showSidebar && !isSidebarCollapsed ? 'ml-[200px]' : 'ml-0'
            } flex justify-center p-4`}
        >
          <div className="w-full mx-auto">
            <Routes>
              {/* {(user?.role?.includes('ADMIN') || user?.role?.includes('SITEOWNER')) && (
                <Route path="/upload" element={<Upload />} />
              )} */}
              <Route
                path="/upload"
                element={
                  (user?.role?.includes('ADMIN') || user?.role?.includes('SITEOWNER'))
                    ? <Upload />
                    : <Navigate to="/unauthorized" />
                }
              />
              {user?.role?.includes('ADMIN') && (
                <Route path="/analytics" element={<Analytics />} />
              )}
              <Route path="/deviceupload" element={<CameraCapture />} />
              <Route path="/viewpdf" element={<ViewPDF />} />
              <Route path="/results" element={<ViewResults />} />
              <Route path="/details/:fileName" element={<ViewDetails />} />
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/login" element={<Login onLogin={handleLogin} />} />
              {user?.role?.includes('SITEOWNER') && (
                <>
                  <Route path="/newcompany" element={<AddCompany />} />
                  <Route path="/newuser" element={<AddUser />} />
                  <Route path="/contacts" element={<ContactMessages />} />

                </>
              )}
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;