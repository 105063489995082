import React, { useState } from 'react';
import logo from './assets/aidocx.jpg';
import solution from './assets/solution.jpg';
import advantage from './assets/advantage.jpg';
import clock from './assets/clock.jpg';
import casestudy from './assets/casestudy.jpg';
import axios from 'axios';
import Header from './Header';
import AIWorkflow from './assets/AIworkflow.jpg';
const Home = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    phone: '',
    message: ''
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id.replace('hs-tac-input-', '').replace('hs-tac-', '')]: value  // Updated to handle both input and textarea
    }));
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/addcontact', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setAlertMessage('Contact information submitted successfully!');
        setShowAlert(true);
        // Reset form after successful submission
        setFormData({
          name: '',
          email: '',
          company: '',
          phone: '',
          message: ''
        });
      } else {
        setAlertMessage('Failed to submit contact information. Please try again.');
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage('An error occurred. Please try again later.');
      setShowAlert(true);
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <div className="bg-neutral-900">

      <Header logo={logo} />
      <main id="content">
        <div className="max-w-5xl mx-auto px-4 xl:px-0 py-12 lg:py-12">
          <div className=" mb-10 lg:mb-4">
            <h2 className="text-white font-semibold text-2xl md:text-4xl md:leading-tight">Transform Logistics Operations with AI-Powered Document Automation</h2>
            <br />
            <p className="mt-2 text-neutral-300">
              At <span style={{ fontWeight: 'bold' }}>A<span style={{ color: 'orange' }}>i</span>Docx </span>, we are committed to providing the best solutions for US logistics industry by eliminating the inefficiencies caused by manual document processing. Our team of experts will help you transform your ideas into reality.
            </p>
            <img className="mt-8 rounded-md" src={advantage} alt="our advantae" />
          </div>
        </div>

        <div className="bg-neutral-900 ">

          <div className="max-w-5xl px-4 xl:px-0 py-2 mx-auto">
            <div className=" mb-10 lg:mb-10">
              <h2 className="text-white font-semibold text-2xl md:text-4xl md:leading-tight">Our Solution</h2>
              <br />
              <p className="mt-2 text-neutral-300">Our Solution is a Customizable AI-powered IDP model that can be tailored for your processes, offering unique benefits and capabilities for enhanced efficiency and quality.
                Intelligent Document Processing (IDP) solution leverages advanced AI and automation to streamline the handling of billions of critical logistics documents, including bills of lading, shipping manifests, customs forms etc.
                By reducing errors, accelerating workflows, and cutting costs, we empower logistics companies to operate more efficiently, improve compliance, and scale their operations seamlessly in a competitive, fast-paced market.
              </p>
              <img className="mt-8 rounded-md" src={solution} alt="our solution" />
            </div>
            <div className="max-w-3xl mb-4 lg:mb-10">
              <h2 className="text-white font-semibold text-2xl md:text-4xl md:leading-tight">
                Why use A<span style={{ color: 'orange' }}>i</span>Docx?
              </h2>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 items-center border border-neutral-700 divide-y lg:divide-y-0 lg:divide-x divide-neutral-700 rounded-xl">
              <div className="group relative z-10 p-4 md:p-6 h-full flex flex-col bg-neutral-900 first:rounded-t-xl last:rounded-b-xl lg:first:rounded-l-xl lg:first:rounded-tr-none lg:last:rounded-r-xl lg:last:rounded-bl-none before:absolute before:inset-0 before:bg-gradient-to-b before:hover:from-transparent before:hover:via-transparent before:hover:to-[#ff0]/10 before:via-80% before:-z-[1] before:last:rounded-b-xl lg:before:first:rounded-s-xl lg:before:last:rounded-e-xl lg:before:last:rounded-bl-none before:opacity-0 before:hover:opacity-100">

                {/* eslint-disable-next-line */}
                < img className="relative z-[2] flex-shrink-0 size-8 rounded-full border-[3px] border-neutral-800" src={clock} />

                <div className="mt-5">
                  <p className="font-semibold text-5xl text-white">&gt;90%</p>
                  <h3 className="mt-5 font-medium text-lg text-white">Reduction in turn around time</h3>
                  <p className="mt-1 text-neutral-400">Real time document processing using our IDP solution tailored to clients need and workflow</p>
                </div>
              </div>
              {/* <p className="mt-auto">
              <span className="font-medium text-sm text-[#ff0] pb-1 border-b-2 border-neutral-700 group-hover:border-[#ff0] transition focus:outline-none group-focus:border-[#ff0]">
                Case study
              </span>
            </p> */}

              <div className="group relative z-10 p-4 md:p-6 h-full flex flex-col bg-neutral-900 first:rounded-t-xl last:rounded-b-xl lg:first:rounded-l-xl lg:first:rounded-tr-none lg:last:rounded-r-xl lg:last:rounded-bl-none before:absolute before:inset-0 before:bg-gradient-to-b before:hover:from-transparent before:hover:via-transparent before:hover:to-[#ff0]/10 before:via-80% before:-z-[1] before:last:rounded-b-xl lg:before:first:rounded-s-xl lg:before:last:rounded-e-xl lg:before:last:rounded-bl-none before:opacity-0 before:hover:opacity-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="green" className="size-8 align-middle" >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
                </svg>


                <div className="mt-5">
                  <p className="font-semibold text-5xl text-white">98%+</p>
                  <h3 className="mt-5 font-medium text-lg text-white">Accuracy</h3>
                  <p className="mt-1 text-neutral-400">Custom models trained for specific document types ensures higher accuracy</p>
                </div>
              </div>

              <div className="group relative z-10 p-4 md:p-6 h-full flex flex-col bg-neutral-900 first:rounded-t-xl last:rounded-b-xl lg:first:rounded-l-xl lg:first:rounded-tr-none lg:last:rounded-r-xl lg:last:rounded-bl-none before:absolute before:inset-0 before:bg-gradient-to-b before:hover:from-transparent before:hover:via-transparent before:hover:to-[#ff0]/10 before:via-80% before:-z-[1] before:last:rounded-b-xl lg:before:first:rounded-s-xl lg:before:last:rounded-e-xl lg:before:last:rounded-bl-none before:opacity-0 before:hover:opacity-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="size-8">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>



                <div className="mt-5">
                  <p className="font-semibold text-5xl text-white">70%</p>
                  <h3 className="mt-5 font-medium text-lg text-white">Cost Reduction</h3>
                  <p className="mt-1 text-neutral-400"> Reducing manual effort for data entry and validation.</p>
                </div>
              </div>


            </div>
            <div className="mt-10 mb-10 lg:mb-10">
              <h2 className="text-white font-semibold text-2xl md:text-4xl md:leading-tight">Document Processing Workflow</h2>
              <br />
              <img className="mt-8 rounded-md" src={AIWorkflow} alt="AI workflow" />
            </div>

          </div>
        </div>
        <div className="bg-neutral-900">
          <div className="max-w-5xl px-4 xl:px-0 py-6 lg:py-20 mx-auto">
            <div className="max-w-3xl mb-4 lg:mb-14">
              <h2 className="text-white font-semibold text-2xl md:text-4xl md:leading-tight">Case Study</h2>
            </div>

            <div className="w-full lg:w-full">
              {/* eslint-disable-next-line */}
              <img className="w-full rounded-xl" src={casestudy} />
            </div>
          </div>
        </div>

        <div className="bg-neutral-900">
          <div className="max-w-5xl px-4 xl:px-0 py-6 lg:pt-20  mx-auto">
            <div className="mb-6 lg:mb-14">
              <h2 className="text-white font-semibold text-2xl md:text-4xl md:leading-tight">Our approach</h2>
              <p className="mt-1 text-neutral-400">Our approach to solving client problems with Intelligent Document Processing (IDP) revolves around leveraging advanced AI and machine learning to automate the extraction, categorization, and analysis of unstructured data. What sets us apart is our tailored solutions, combining cutting-edge technology with deep industry insights, ensuring seamless integration with client workflows. We focus on improving accuracy, speed, and scalability, enabling clients to extract actionable insights from complex documents. Our solutions are adaptable to unique business needs, driving both operational efficiency and informed decision-making.</p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-16 lg:items-center">
              <div className="aspect-w-16 aspect-h-9 lg:aspect-none">
                {/* eslint-disable-next-line */}
                <img className="w-full object-cover rounded-xl" src="https://images.unsplash.com/photo-1587614203976-365c74645e83?q=80&w=480&h=600&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Image Description" />
              </div>
              <div>
                <div className="mb-4">
                  <h3 className="text-sm font-medium uppercase text-orange-400">
                    Steps
                  </h3>
                </div>
                <div className="flex gap-x-5 ms-1">
                  <div className="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-neutral-800">
                    <div className="relative z-10 size-8 flex justify-center items-center">
                      <span className="flex flex-shrink-0 justify-center items-center size-8 border border-orange-400 text-orange-400 font-semibold text-xs uppercase rounded-full">
                        1
                      </span>
                    </div>
                  </div>

                  <div className="grow pt-0.5 pb-8 sm:pb-12">
                    <p className="text-sm lg:text-base text-neutral-400">
                      <span className="text-white">Research and Needs Assessment:</span>
                      Identify key pain points related to document management and data extraction, understanding industry-specific requirements
                    </p>
                  </div>

                </div>

                <div className="flex gap-x-5 ms-1">

                  <div className="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-neutral-900">
                    <div className="relative z-10 size-8 flex justify-center items-center">
                      <span className="flex flex-shrink-0 justify-center items-center size-8 border border-orange-400 text-orange-400 font-semibold text-xs uppercase rounded-full">
                        2
                      </span>
                    </div>
                  </div>

                  <div className="grow pt-0.5 pb-8 sm:pb-12">
                    <p className="text-sm lg:text-base text-neutral-400">
                      <span className="text-white">Solution Development and Testing</span>
                      Build and train AI models for extracting and classifying unstructured data. Test these models for accuracy and efficiency on real client documents.
                    </p>
                  </div>

                </div>

                <div className="flex gap-x-5 ms-1">

                  <div className="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-neutral-800">
                    <div className="relative z-10 size-8 flex justify-center items-center">
                      <span className="flex flex-shrink-0 justify-center items-center size-8 border border-orange-400 text-orange-400 font-semibold text-xs uppercase rounded-full">
                        3
                      </span>
                    </div>
                  </div>

                  <div className="grow pt-0.5 pb-8 sm:pb-12">
                    <p className="text-sm md:text-base text-neutral-400">
                      <span className="text-white">Implementation and Integration:</span>
                      Deploy the solution, ensuring seamless integration with existing workflows and systems.
                    </p>
                  </div>
                </div>
                <div className="flex gap-x-5 ms-1">
                  <div className="relative last:after:hidden after:absolute after:top-8 after:bottom-0 after:start-4 after:w-px after:-translate-x-[0.5px] after:bg-neutral-800">
                    <div className="relative z-10 size-8 flex justify-center items-center">
                      <span className="flex flex-shrink-0 justify-center items-center size-8 border border-orange-400 text-orange-400 font-semibold text-xs uppercase rounded-full">
                        4
                      </span>
                    </div>
                  </div>
                  <div className="grow pt-0.5 pb-8 sm:pb-12">
                    <p className="text-sm md:text-base text-neutral-400">
                      <span className="text-white">Launch, Optimize and Continuous Improvement:</span>
                      Roll out the solution while monitoring its performance, gathering feedback, and continuously optimizing based on evolving needs.
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="bg-neutral-900">
          <div className="max-w-5xl px-4 xl:px-0 py-10 lg:py-20 mx-auto">
            <div className="max-w-3xl mb-10 lg:mb-6" id="contact">
              <h2 className="text-white font-semibold text-2xl md:text-4xl md:leading-tight">Contact us</h2>
              <p className="mt-1 text-neutral-400">We would love to hear from you...</p>
            </div>

            {showAlert && (
              <div id="alert-1" className="flex items-center p-4 mb-4 text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
                <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="sr-only">Info</span>
                <div className="ms-3 text-sm font-medium">
                  {alertMessage}
                </div>
                <button
                  type="button"
                  className="ms-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-blue-400 dark:hover:bg-gray-700"
                  onClick={handleCloseAlert}
                  aria-label="Close"
                >
                  <span className="sr-only">Close</span>
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                </button>
              </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 lg:gap-x-16">
              <div className="md:order-2 border-b border-neutral-800 pb-10 mb-10 md:border-b-0 md:pb-0 md:mb-0">
                <form onSubmit={handleContactSubmit}>
                  <div className="space-y-4">
                    <div className="relative">
                      <input
                        type="text"
                        id="hs-tac-input-name"
                        className="peer p-4 block w-full bg-neutral-800 border-transparent rounded-lg text-sm text-white placeholder:text-transparent focus:outline-none focus:ring-0 focus:border-transparent disabled:opacity-50 disabled:pointer-events-none focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="hs-tac-input-name" className="absolute top-0 start-0 p-4 h-full text-neutral-400 text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:text-xs peer-focus:-translate-y-1.5 peer-focus:text-neutral-400 peer-[:not(:placeholder-shown)]:text-xs peer-[:not(:placeholder-shown)]:-translate-y-1.5 peer-[:not(:placeholder-shown)]:text-neutral-400">Name</label>
                    </div>

                    <div className="relative">
                      <input
                        type="email"
                        id="hs-tac-input-email"
                        className="peer p-4 block w-full bg-neutral-800 border-transparent rounded-lg text-sm text-white placeholder:text-transparent focus:outline-none focus:ring-0 focus:border-transparent disabled:opacity-50 disabled:pointer-events-none focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="hs-tac-input-email" className="absolute top-0 start-0 p-4 h-full text-neutral-400 text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:text-xs peer-focus:-translate-y-1.5 peer-focus:text-neutral-400 peer-[:not(:placeholder-shown)]:text-xs peer-[:not(:placeholder-shown)]:-translate-y-1.5 peer-[:not(:placeholder-shown)]:text-neutral-400">Email</label>
                    </div>

                    <div className="relative">
                      <input
                        type="text"
                        id="hs-tac-input-company"
                        className="peer p-4 block w-full bg-neutral-800 border-transparent rounded-lg text-sm text-white placeholder:text-transparent focus:outline-none focus:ring-0 focus:border-transparent disabled:opacity-50 disabled:pointer-events-none focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2"
                        placeholder="Company"
                        value={formData.company}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="hs-tac-input-company" className="absolute top-0 start-0 p-4 h-full text-neutral-400 text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:text-xs peer-focus:-translate-y-1.5 peer-focus:text-neutral-400 peer-[:not(:placeholder-shown)]:text-xs peer-[:not(:placeholder-shown)]:-translate-y-1.5 peer-[:not(:placeholder-shown)]:text-neutral-400">Company</label>
                    </div>

                    <div className="relative">
                      <input
                        type="text"
                        id="hs-tac-input-phone"
                        className="peer p-4 block w-full bg-neutral-800 border-transparent rounded-lg text-sm text-white placeholder:text-transparent focus:outline-none focus:ring-0 focus:border-transparent disabled:opacity-50 disabled:pointer-events-none focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="hs-tac-input-phone" className="absolute top-0 start-0 p-4 h-full text-neutral-400 text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:text-xs peer-focus:-translate-y-1.5 peer-focus:text-neutral-400 peer-[:not(:placeholder-shown)]:text-xs peer-[:not(:placeholder-shown)]:-translate-y-1.5 peer-[:not(:placeholder-shown)]:text-neutral-400">Phone</label>
                    </div>

                    <div className="relative">
                      <textarea
                        id="hs-tac-message"  // Keep this id as is
                        className="peer p-4 block w-full bg-neutral-800 border-transparent rounded-lg text-sm text-white placeholder:text-transparent focus:outline-none focus:ring-0 focus:border-transparent disabled:opacity-50 disabled:pointer-events-none focus:pt-6 focus:pb-2 [&:not(:placeholder-shown)]:pt-6 [&:not(:placeholder-shown)]:pb-2 autofill:pt-6 autofill:pb-2"
                        placeholder="This is a textarea placeholder"
                        value={formData.message}  // Changed from formData['hs-tac-message'] to formData.message
                        onChange={handleInputChange}
                      ></textarea>
                      <label htmlFor="hs-tac-message" className="absolute top-0 start-0 p-4 h-full text-neutral-400 text-sm truncate pointer-events-none transition ease-in-out duration-100 border border-transparent peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:text-xs peer-focus:-translate-y-1.5 peer-focus:text-neutral-400 peer-[:not(:placeholder-shown)]:text-xs peer-[:not(:placeholder-shown)]:-translate-y-1.5 peer-[:not(:placeholder-shown)]:text-neutral-400">Tell us about your project</label>
                    </div>

                  </div>

                  <div className="mt-2">
                    <p className="text-xs text-neutral-500">
                      All fields are required
                    </p>

                    <p className="mt-5 items-center justify-center text-center">
                      <button type="submit" className="group inline-flex items-center gap-x-2 py-2 px-3 bg-orange-400 font-medium text-sm text-neutral-800 rounded-full focus:outline-none">
                        <svg className="flex-shrink-0 size-4 transition group-hover:translate-x-0 group-focus:translate-x-0.5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M5 12h14" />
                          <path d="m12 5 7 7-7 7" />
                        </svg>
                        Submit
                      </button>
                    </p>
                  </div>
                </form>
              </div>


              <div className="space-y-14">
                <div className="flex gap-x-5">
                  <svg className="flex-shrink-0 size-6 text-neutral-500" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" />
                    <circle cx="12" cy="10" r="3" />
                  </svg>
                  <div className="grow">
                    <h4 className="text-white font-semibold">Our address:</h4>

                    <address className="mt-1 text-neutral-400 text-sm not-italic">
                      Mountain Brook<br />
                      Alabama, United States
                    </address>
                  </div>
                </div>
                <div className="flex gap-x-5">
                  <svg className="flex-shrink-0 size-6 text-neutral-500" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z" />
                    <path d="m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10" />
                  </svg>
                  <div className="grow">
                    <h4 className="text-white font-semibold">Email us:</h4>

                    <a className="mt-1 text-neutral-400 text-sm" href="#mailto:example@site.co" target="_blank">
                      info@aidocx.co
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </main >
      <footer className="relative overflow-hidden bg-neutral-900">
        <svg className="absolute -bottom-20 start-1/2 w-[1900px] transform -translate-x-1/2" width="2745" height="488" viewBox="0 0 2745 488" fill="none" >
          <path d="M0.5 330.864C232.505 403.801 853.749 527.683 1482.69 439.719C2111.63 351.756 2585.54 434.588 2743.87 487" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 308.873C232.505 381.81 853.749 505.692 1482.69 417.728C2111.63 329.765 2585.54 412.597 2743.87 465.009" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 286.882C232.505 359.819 853.749 483.701 1482.69 395.738C2111.63 307.774 2585.54 390.606 2743.87 443.018" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 264.891C232.505 337.828 853.749 461.71 1482.69 373.747C2111.63 285.783 2585.54 368.615 2743.87 421.027" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 242.9C232.505 315.837 853.749 439.719 1482.69 351.756C2111.63 263.792 2585.54 346.624 2743.87 399.036" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 220.909C232.505 293.846 853.749 417.728 1482.69 329.765C2111.63 241.801 2585.54 324.633 2743.87 377.045" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 198.918C232.505 271.855 853.749 395.737 1482.69 307.774C2111.63 219.81 2585.54 302.642 2743.87 355.054" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 176.927C232.505 249.864 853.749 373.746 1482.69 285.783C2111.63 197.819 2585.54 280.651 2743.87 333.063" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 154.937C232.505 227.873 853.749 351.756 1482.69 263.792C2111.63 175.828 2585.54 258.661 2743.87 311.072" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 132.946C232.505 205.882 853.749 329.765 1482.69 241.801C2111.63 153.837 2585.54 236.67 2743.87 289.082" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 110.955C232.505 183.891 853.749 307.774 1482.69 219.81C2111.63 131.846 2585.54 214.679 2743.87 267.091" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 88.9639C232.505 161.901 853.749 285.783 1482.69 197.819C2111.63 109.855 2585.54 192.688 2743.87 245.1" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 66.9729C232.505 139.91 853.749 263.792 1482.69 175.828C2111.63 87.8643 2585.54 170.697 2743.87 223.109" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 44.9819C232.505 117.919 853.749 241.801 1482.69 153.837C2111.63 65.8733 2585.54 148.706 2743.87 201.118" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 22.991C232.505 95.9276 853.749 219.81 1482.69 131.846C2111.63 43.8824 2585.54 126.715 2743.87 179.127" className="stroke-neutral-700/50" stroke="currentColor" />
          <path d="M0.5 1C232.505 73.9367 853.749 197.819 1482.69 109.855C2111.63 21.8914 2585.54 104.724 2743.87 157.136" className="stroke-neutral-700/50" stroke="currentColor" />
        </svg>

        <div className="relative z-10">
          <div className="w-full max-w-5xl px-4 xl:px-0 py-10 lg:pt-16 mx-auto">
            <div className="flex items-center">
              <img className="relative z-[2] flex-shrink-0 w-20 h-20 rounded-full border-[3px] border-neutral-800" src={logo} alt="AI DOCX logo" />
              <div className="border-l border-neutral-700 pl-5 ml-5 text-sm text-neutral-400">
                2024 AiDocx.co
              </div>
              <div className="border-l border-neutral-700 pl-5 ml-5 text-sm text-neutral-400">
                <a href="/privacy" className="text-sm text-neutral-400">
                  Privacy Policy
                </a>
              </div>

            </div>
          </div>
        </div>
      </footer>



    </div >
  );
};

export default Home;