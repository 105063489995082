import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddUser = ({ userRole }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [selectedCompanyName, setSelectedCompanyName] = useState('');
    const options = ['REVIEWER', 'ADMIN', 'AUDITOR', 'SITEOWNER'];

    const [user, setUser] = useState({
        email: '',
        password: ''
    });

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const getCompanies = async () => {
            try {
                const response = await axios.get('/companies');
                setCompanies(response.data.companies);
            } catch (err) {
                console.error('Error fetching companies:', err);
            }
        };

        getCompanies();
    }, []);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedOptions([...selectedOptions, value]);
        } else {
            setSelectedOptions(selectedOptions.filter(option => option !== value));
        }
    };

    const handleCompanyChange = (e) => {
        const selectedName = e.target.value;
        const selectedCompany = companies.find(company => company.company_name === selectedName);

        if (selectedCompany) {
            setSelectedCompanyId(selectedCompany.company_id);
            setSelectedCompanyName(selectedCompany.company_name);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        setSuccess(false);

        const newUser = {
            ...user,
            company_id: selectedCompanyId,
            company_name: selectedCompanyName,
            role: selectedOptions,
        };

        try {
            await axios.post('/adduser', newUser, {
                headers: { 'Content-Type': 'application/json' }
            });
            setSuccess(true);
            setUser({ email: '', password: '' });
            setSelectedOptions([]);
            setSelectedCompanyId('');
            setSelectedCompanyName('');
        } catch (err) {
            setError(err.response?.data?.message || err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const inputClassName = "mt-1 block w-full rounded-md border-gray-300 border-2 px-3.5 py-2 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50";
    const labelClassName = "block text-sm font-medium text-gray-700";

    return (
        <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-6">Add New User</h2>
            <p className="text-gray-600 mb-6">Enter the details for the new user account.</p>
            
            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                    <strong className="font-bold">Error!</strong>
                    <span className="block sm:inline"> {error}</span>
                </div>
            )}
            {success && (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
                    <strong className="font-bold">Success!</strong>
                    <span className="block sm:inline"> User added successfully!</span>
                </div>
            )}
            
            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label htmlFor="email" className={labelClassName}>Email</label>
                    <input id="email" type="email" name="email" value={user.email} onChange={handleChange} required className={inputClassName} />
                </div>

                <div>
                    <label htmlFor="password" className={labelClassName}>Choose Password</label>
                    <input id="password" type="password" name="password" value={user.password} onChange={handleChange} required className={inputClassName} />
                </div>

                <div>
                    <label htmlFor="company-select" className={labelClassName}>Select a company</label>
                    <select id="company-select" onChange={handleCompanyChange} value={selectedCompanyName} className={inputClassName}>
                        <option value="">-- Select Company --</option>
                        {companies?.map((company) => (
                            <option key={company.company_id} value={company.company_name}>
                                {company.company_name}
                            </option>
                        ))}
                    </select>
                </div>
                
                <div>
                    <label className={labelClassName}>Choose Roles</label>
                    <div className="mt-2 space-y-2">
                        {options.map((option, index) => (
                            <div key={index} className="flex items-center">
                                <input
                                    id={`role-${option}`}
                                    type="checkbox"
                                    value={option}
                                    checked={selectedOptions.includes(option)}
                                    onChange={handleCheckboxChange}
                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label htmlFor={`role-${option}`} className="ml-2 block text-sm text-gray-900">
                                    {option}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <button
                        type="submit"
                        disabled={isLoading}
                        className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                    >
                        {isLoading ? 'Adding...' : 'Add User'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddUser;