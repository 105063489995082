import React, { useState } from 'react';
import axios from 'axios';

const AddCompany = ({ userRole }) => {
    const [company, setCompany] = useState({
        name: '',
        address: '',
        city: '',
        state: '',
        zip:'',
        country: 'USA',
        phone: '',
        email: '',
        website: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCompany(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        setSuccess(false);
    
        try {
            // Make the POST request using axios
            const response = await axios.post('/addcompany', company, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            // Check for errors (axios will throw if status >= 400, so no need to manually check response.ok)
            setSuccess(true);
            setCompany({
                name: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                country: 'USA',
                phone: '',
                email: '',
                website: ''
            });
        } catch (err) {
            setError(err.response?.data?.message || err.message); // Handle axios error
        } finally {
            setIsLoading(false);
        }
    };

    const inputClassName = "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6";
    const labelClassName = "block text-sm font-semibold leading-6 text-gray-900";

    return (
        <div className="max-w-2xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-5">Add New Company</h2>
            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                    <strong className="font-bold">Error!</strong>
                    <span className="block sm:inline"> {error}</span>
                </div>
            )}
            {success && (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
                    <strong className="font-bold">Success!</strong>
                    <span className="block sm:inline"> Company added successfully!</span>
                </div>
            )}
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="name" className={labelClassName}>Company Name</label>
                    <input id="name" type="text" name="name" value={company.name} onChange={handleChange} required className={inputClassName} />
                </div>
                <div>
                    <label htmlFor="address" className={labelClassName}>Address</label>
                    <input id="address" type="text" name="address" value={company.address} onChange={handleChange} required className={inputClassName} />
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="city" className={labelClassName}>City</label>
                        <input id="city" type="text" name="city" value={company.city} onChange={handleChange} className={inputClassName} />
                    </div>
                    <div>
                        <label htmlFor="state" className={labelClassName}>State</label>
                        <input id="state" type="text" name="state" value={company.state} onChange={handleChange} className={inputClassName} />
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="zip" className={labelClassName}>ZIP Code</label>
                        <input id="zip" type="text" name="zip" value={company.zip} onChange={handleChange} className={inputClassName} />
                    </div>
                    <div>
                        <label htmlFor="country" className={labelClassName}>Country</label>
                        <input id="country" type="text" name="country" value={company.country} onChange={handleChange} className={inputClassName} />
                    </div>
                </div>
                <div>
                    <label htmlFor="phone" className={labelClassName}>Phone</label>
                    <input id="phone" type="tel" name="phone" value={company.phone} onChange={handleChange} className={inputClassName} />
                </div>
                <div>
                    <label htmlFor="email" className={labelClassName}>Email</label>
                    <input id="email" type="email" name="email" value={company.email} onChange={handleChange} required className={inputClassName} />
                </div>
                <div>
                    <label htmlFor="website" className={labelClassName}>Website</label>
                    <input id="website" type="text" name="website" value={company.website} onChange={handleChange} className={inputClassName} />
                </div>
                <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
                >
                    {isLoading ? 'Adding...' : 'Add Company'}
                </button>
            </form>
        </div>
    );
};

export default AddCompany;