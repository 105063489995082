import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from './AuthProvider';
import axios from 'axios';

const CameraCapture = () => {
  const [imageSrc, setImageSrc] = useState(null);  // Store captured image as data URL
  const [error, setError] = useState(null);        // Store any potential errors
  const [stream, setStream] = useState(null);
  const [facingMode, setFacingMode] = useState('user'); // 'user' for front camera, 'environment' for rear
  
  const videoRef = useRef(null);                   // Ref to access video element
  const canvasRef = useRef(null);  
  const {user} = useAuth();                
  

  useEffect(() => {
    startCamera();
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [facingMode]);

  const flipCamera = () => {
    setFacingMode(prevMode => prevMode === 'user' ? 'environment' : 'user');
  };
  // Start the camera stream when the component mounts
  const startCamera = async () => {
    setError(null);
    try {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
      const newStream = await navigator.mediaDevices.getUserMedia({ 
        video: { facingMode: facingMode } 
      });
      setStream(newStream);
      if (videoRef.current) {
        videoRef.current.srcObject = newStream;
      }
    } catch (err) {
      setError('Camera access denied or not available.');
      console.error(err);
    }
  };

  // Capture an image from the video stream
  const captureImage = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext('2d');
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
      const image = canvasRef.current.toDataURL('image/jpeg');
      setImageSrc(image); // Save the captured image as a data URL
    }
  };

  // Upload the image to an API endpoint
  const uploadImage = async () => {
    if (!imageSrc) return;
  
    try {
      // Convert the data URL to a Blob
      const response = await fetch(imageSrc);
      const blob = await response.blob();
      const formData = new FormData();
      
      // Generate the current time without colons
      const now = new Date();
      const time = now.toLocaleTimeString('en-US', { hour12: false }).replace(/:/g, '');
  
      // Append the blob with a dynamically generated filename
      formData.append('file', blob, `${user.company}${time}.jpg`);
      formData.append('company', user.company);
  
      console.log('FormData contents:');
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }

      // Upload image using axios
      const uploadResponse = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Check response and alert user
      if (uploadResponse.status === 200) {
        alert('Image uploaded successfully!');
      } else {
        alert('Image upload failed!');
      }
    } catch (err) {
      console.error('Error uploading image:', err);
      alert('Error uploading image: ' + err.message);
    }
  };


  return (
    <div>
        <div className='flex justify-center'>
      <h2 className="text-xl font-bold mb-4">Take a Picture</h2>
      </div>
      {/* Error Handling */}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Video Preview */}
      <div>
        <video ref={videoRef} autoPlay style={{ width: '100%', maxHeight: '400px' }} />
        <canvas ref={canvasRef} style={{ display: 'none' }} /> {/* Used to capture image */}
      </div>

    <div className='flex justify-center pr-2 space-x-4'>
      {/* Start Camera Button */}
      <button onClick={startCamera} className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Start Camera</button>

      {/* Capture Image Button */}
      <button onClick={captureImage} className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ">Capture Image</button>

      {/* Flip Camera Button */}
      <button onClick={flipCamera} className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ">Flip Camera</button>
      
    </div>
      {/* Display Captured Image */}
      {imageSrc && (
        <div>
          <h3>Captured Image:</h3>
          <img src={imageSrc} alt="Captured" style={{ width: '100%', maxHeight: '400px' }} />
          <br />
          <button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" onClick={uploadImage}>Upload Image</button>
        </div>
      )}
    </div>
  );
};

export default CameraCapture;
