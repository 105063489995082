import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useAuth } from './AuthProvider';

const Upload = () => {
  const [files, setFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [processing, setProcessing] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({});
  const fileInputRef = useRef(null);
  const { user } = useAuth();

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    updateFiles(newFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const newFiles = Array.from(e.dataTransfer.files);
    updateFiles(newFiles);
  };

  const updateFiles = (newFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleUploadAll = async () => {
    let uploadedCount = 0;
    let failedCount = 0;
    let alreadyProcessedCount = 0;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (uploadedFiles[file.name]) {
        setProcessing((prevProcessing) => ({
          ...prevProcessing,
          [i]: 'Already Processed',
        }));
        alreadyProcessedCount++;
        continue;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('company', user.company);

      setProcessing((prevProcessing) => ({
        ...prevProcessing,
        [i]: 'Uploading...',
      }));

      try {
        await axios.post('/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setProcessing((prevProcessing) => ({
          ...prevProcessing,
          [i]: 'Uploaded',
        }));

        setUploadedFiles((prevUploadedFiles) => ({
          ...prevUploadedFiles,
          [file.name]: true,
        }));

        uploadedCount++;
      } catch (error) {
        console.error('Error uploading file:', error);
        setProcessing((prevProcessing) => ({
          ...prevProcessing,
          [i]: 'Failed',
        }));
        failedCount++;
      }
    }

    // Final log after all uploads
    console.log("Total File count: ", files.length);
    console.log("Uploaded Files count: ", uploadedCount);
    console.log("Failed Files count: ", failedCount);
    console.log("Already Processed Files count: ", alreadyProcessedCount);
    console.log(uploadedFiles);
    try {
      console.log(user);
      console.log("Sending analytics data");
      await axios.post('/analytics', {
        company_name: user.company,
        username: user.username,
        totalFiles: files.length,
        uploadedFiles: uploadedCount,
        failedFiles: failedCount,
        alreadyProcessedFiles: alreadyProcessedCount
      });
    } catch (error) {
      console.error('Error sending analytics data:', error);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Uploaded':
        return 'text-green-500';
      case 'Failed':
        return 'text-red-500';
      case 'Uploading...':
        return 'text-yellow-500';
      default:
        return 'text-gray-500';
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-6 space-y-6">
      <div
        className={`flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer ${dragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 bg-gray-50'
          } hover:bg-gray-100 transition-colors duration-300`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleClick();
          }
        }}
        tabIndex="0"
        role="button"
        aria-label="Click to upload files or drag and drop files here"
      >
        <svg className="w-12 h-12 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
        </svg>
        <p className="mb-2 text-sm text-gray-500">
          <span className="font-semibold">Click to upload</span> or drag and drop
        </p>
        <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
        <input
          ref={fileInputRef}
          id="dropzone-file"
          type="file"
          className="hidden"
          onChange={handleFileChange}
          multiple
          aria-hidden="true"
        />
      </div>

      {files.length > 0 && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-900 uppercase tracking-wider">File Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-900 uppercase tracking-wider">Status</th>
                {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Progress</th> */}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {files.map((file, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{file.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <span className={`${getStatusColor(processing[index])}`}>
                      {processing[index] || 'Waiting'}
                    </span>
                  </td>
                  {/* <td className="px-6 py-4 whitespace-nowrap">
                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                      <div 
                        className="bg-blue-600 h-2.5 rounded-full" 
                        style={{ width: processing[index] === 'Processed' ? '100%' : '0%' }}
                      ></div>
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <button
        onClick={handleUploadAll}
        className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${files.length === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
          }`}
        disabled={files.length === 0}
      >
        Upload All
      </button>
    </div>
  );
};

export default Upload;